<template>
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">
        <div class="az-profile-overview">
          <div class="az-img-user">
            <div id="profile-upload" :style="user_image_background">
              <div class="hvr-profile-img">
                <input type="file" name="logo" class="upload w180" title="Click to upload image" id="getval" @change="fileChange">
              </div>
              <i class="fa fa-2x fa-camera"></i>
            </div>
          </div>
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 class="az-profile-name" v-if="currentUser">{{currentUser.name}}</h5>
              <p class="az-profile-name-text">{{currentUser.email}}</p>
            </div>
          </div>
          <div class="az-profile-bio">

          </div>
          <hr class="mg-y-30">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>

          <!-- <div class="az-content-label tx-13 mg-b-25">Contact Information</div>
          <div class="az-profile-contact-list">
            <div class="media">
              <div class="media-icon"><i class="icon ion-md-locate"></i></div>
              <div class="media-body">
                <span>Current Address</span>
                <div></div>
                <div></div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="az-content-body az-content-body-profile">
        <div class="az-tab-content">
          <div :class="{'d-none': activeTab !== 0}" class="az-profile-body" id="account">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Change Password</h6>
                      <div class="mg-l-auto">
                        <router-link class="router-link-active" :to="{ name: 'myaccount' }">
                          <i class="fa fa-arrow-left" /> Back
                        </router-link>
                      </div>
                    </div>
                    <p></p>
                    <div class="mg-t-35">

                      <label class="form-control-label">Old Password: </label>
                      <input class="form-control rounded" id="oldpassword" name="oldpassword" placeholder="" type="password" v-model="oldpassword">

                      <label class="form-control-label">New Password: </label>
                      <input class="form-control rounded" id="newpassword" name="newpassword" placeholder="" type="password" v-model="newpassword">

                      <label class="form-control-label">Confirm New Password: </label>
                      <input class="form-control rounded" id="confirmnewpassword" name="confirmnewpassword" placeholder="" type="password" v-model="confirmnewpassword">

                    </div>

                    <div class="row row-sm form-group mg-t-10">
                      <div class="col-lg-3 offset-lg-10">
                        <button @click="update" class="btn btn-orange rounded w-80 font-weight-bold mg-l-35" title="Add Catalog">
                          <i class="fas fa-save" />&nbsp;&nbsp;Update password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "Edit",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      activeTab: 0,
      image: null,
      src: null,
      user_image_background: localStorage.getItem("user_image") ? 'background-image:url(\'' + localStorage.getItem("user_image") + '\')' : '',
      oldpassword: '',
      newpassword: '',
      confirmnewpassword: ''
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {

    },
    async update() {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.patch(`/accounts/user`, {
          password: this.newpassword
        });
        this.$toastr.s("Password Successfully Updated!", 'Success');
        await this.$router.push('/myaccount');
      } catch (e) {
        console.log(e);
        this.$toastr.s("Password Update Failed!", 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    fileChange(e) {
      this.image = e.target.files[0];
      this.upload();
    },
    async upload() {
      try {
        EventBus.$emit('openLoader');
        let f = new FormData();
        f.append('file', this.image);
        let user = await this.$accounts.patch(`/accounts/user/picture`, f, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'BWS ' + localStorage.getItem("token")
          }
        });
        let image = user.data.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/');
        localStorage.setItem("user_image", image);
        this.user_image_background = image ? 'background-image:url(\'' + image + '\')' : '';

      } catch (e) {
        console.log(e);
        this.$toastr.e("Failed to upload new image", 'Error');
      } finally {
        EventBus.$emit('closeLoader');

      }
    },
  }
}
</script>

<style scoped>
</style>